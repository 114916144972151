/* Style FOr Login */

.login-wave-head {
    position: relative;
    height: 500px;
    background: #002f62;
    background-image: linear-gradient(155deg, #002f62 0%, #18496a 50%, #138b89 100%);
  }
  
  
  .login-form-wave-svg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
  }
  
  .login-wave section {
    margin: 5% 10%;
  }
  
  .login-form {
    margin-left: 40%;
    margin-top:200px;
    z-index: 1000;
    position: absolute;
    width:350px;
    min-height:380px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    padding: 10px 20px 0 20px;
    background: #ffffff30;
    text-align: center;
    
  }
  
 .login-form h2 {
     color: #fff;
 }

 .login-form .ant-form-item-control-input-content button {
     width: 100%;
     background: #39a039;
     border:none;
 }

 .login-form  .register-login-btn {
     width: 100%;
     margin-bottom: 10px;
 }


@media only screen and (max-width:991px) {
    .login-form {
        margin-left:0;
        width: 100%;
    }
}


/* Panel Custom Css */

.agent-profile-load {
  width: 100%;
  margin: 5px;
}


/* Style For Panel   */
#components-layout-demo-side .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
  
  .panel-logo {
    width:100%;
    height:40px;
    text-align: center;
    padding: 10px 5px 0 5px;
  }
  
  .panel-logo img {
   
    height:100%;
  }
  
  .ant-menu {
    /* margin-top: 20px !important; */
  }
  
  .ant-menu .ant-menu-item-selected {
    background: #fff !important;
    border: none !important;
  
  }
  
  
  
  
  
  .ant-layout-sider-trigger {
    background: #fff !important;
    border-top:1px solid #e0e0e0;
    color:#4b5092 !important;
  }
  
  .ant-layout-sider {
    background: #fff !important;
  }
  
  .add-product-form .leaflet-container {
    height:250px;
    margin-bottom: 10px;
    border-radius: 10px;
    z-index: 0;
  }
  
  #nest-messages .ant-input-number ,
  #formAddSuite .ant-input-number ,
  .add-product-form .ant-input-number {
    width:100%;
  }
  
  .add-product-form .lmMaya {
    border:1px solid #d9d9d9 !important;
  }
  
  .title-list-name {
    cursor: pointer;
    color:#1890ff;
  }
  


  /* Style For New Login */

  .login-amaz {
    width: 100%;
    height: 100%;
    background: #eff7fe;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 15px 0 15px;
  }


  .login-amaz-main {
    width: 500px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }


  .login-amaz-head {
    width: 100%;
    height: 90px;
    background: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .login-amaz-head-button {
    width: 100px;
    height: 35px;
    background: #ddf0fc;
    color: #1c9dea;
    border: none;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .login-amaz-head-button-active {
    background:#1c9dea ;
    color: #fff;
  }

  .login-amaz-cont {
    width: 100%;
    margin-top: 50px;
    min-height: 300px;
    background:#fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 50px;
  }

  .login-amaz-cont img{
    height: 50px;
  }

  .login-amaz-cont h5 {
    font-size: 16px;
    margin-top: 10px;
    color: rgb(73, 70, 70);
  }


  .login-amaz-cont h4 {
    font-size: 20px;
    font-weight: bold;
  }

  .login-amaz-cont #loginAmaz{
    width: 80%;
    margin-top: 40px;
  }

  #loginAmaz .ant-input-affix-wrapper ,#loginAmaz .ant-select-selector {
    height: 45px;
  }

  #loginAmaz .ant-btn {
    height: 45px;
    width: 100%;
  }

  @media only screen and (max-width:650px) {
    .login-amaz-main {
        width: 100%;
    }
    .login-amaz-head {
      flex-direction: column;
      height: auto;
    }
    .login-amaz-head-button {
      margin: 5px;
    }
}

  /* NEW STYLE FOR NEW PANEL */

  
/* Style For Nav */

.nav {
  width: 250px;
  background: #fff;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
  z-index: 20;
}

.navClose {
  width: 70px;
}


.nav-head {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.nav-head img {
  height: 45px;
  margin-top: 11px;
}

.nav-item {
  width: 80%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.nav-head .anticon {
  font-size: 20px;
  margin-top: 25px;
  margin-left: 25px;
  cursor: pointer;
  color: #05bbc9;
  display: none;
}

.nav-item-inner {
  width: 100%;
  height: 45px;
  display: flex;
  border-radius: 5px;
  color: #535f6b;
  margin:5px 0 5px 0;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
}


.nav-item-inner-active {
  background: #cdf1f4;
  color: #05bbc9;
  transition: all 0.3s;
}

.nav-item-inner-small {
  justify-content: center;
}


.nav-item-cont .anticon {
  font-size: 20px;
  margin-left: 10px;
}

.nav-item-cont-icon-small {
  margin-left: 0 !important;
}

.nav-item-cont-title {
  margin-left: 12px;
}

.nav-item-sub {
  width: 0;
  height: 0;
  transform: scale(0);
  margin-top: 0;
  transition: all 0.3s;
  background:#fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: #535f6b;

}

.nav-item-sub-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  color: #535f6b;

}

.nav-item-sub-item-text {
  font-size: 13px;
  margin-left: 12px;
}

.nav-item-sub-active {
  margin-right: 10px;
  transform: scale(1);
  width: 81%;
  height: unset;
  transition: all 0.3s;
}



@media only screen and (max-width:760px) {
  .nav {
      width: 0;
  }
  .nav-head .anticon {
      display: unset;
  }
}


/* Style For Cont Head */

.cont {
  width: calc(100% - 250px);
  background: #f5f6f9;
  margin-left: 250px;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.contWidth {
  width: calc(100% - 70px);
  margin-left: 70px;
}

.contMain {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  display: flex;
  box-sizing: border-box;
}

.contMainPage {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}

.cont-head {
  width: 100%;
  height: 89px;
  background: #f9f9f9;
  border-bottom: 1px solid #f5f6f9;
  display:  flex;
  justify-content: space-around;
  position: relative;
}

.cont-head-left {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.cont-head-left-icon1 {
  font-size: 25px;
  /* margin-left: 20px;  */
  cursor: pointer;
  color: #05bbc9;
}

.cont-head-left-icon2 {
  font-size: 25px;
  /* margin-left: 20px;  */
  cursor: pointer;
  color: #05bbc9;
  display: none;
}

.cont-head-left img {
  height: 40px;
  margin:0px 0 0 25px;
  display: none;
}

.cont-head-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}

.cont-head-right-icon {
  font-size: 25px;
  margin-left: 20px;
  cursor: pointer;
  color: #05bbc9;
  display: none;
  order:1;
}

.cont-head-right-icon svg{
  background: #dff6f8;
  border-radius: 5px;
  padding:5px;
}

.cont-head-right-btn {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 22px;
}

.cont-head-right-btn:nth-last-of-type(1){
  background: #fe721c;
}

.cont-head-right-btn:nth-last-of-type(2){
  background: #876cfe;
}

.cont-head-right-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 30px;
}

.cont-head-sub {
  position: absolute;
  width: 100%;
  height: 0;
  background: #fff;
  top: 100%;
  border-top:1px solid #e0e0e0 ;
  transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  z-index: 20;
}

.cont-head-right-mode {
  width: 65px;
  height: 25px;
  border-radius: 3px;
  border:1px solid #05bbc9;
  margin-right: 20px;
  display: flex;
}

.cont-head-right-mode-active {
  background: #05bbc9;
  color: #fff !important;
}

.cont-head-right-nigh {
  width: 50%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  color: #535f6b;
}

.cont-head-right-day {
  width: 50%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  color: #535f6b;

}




@media only screen and (max-width:760px) {
  .cont {
      width: 100%;
      margin: 0;
  }
  .cont-head {
      background: #fff;
  }
  .cont-head-left-icon1 {
      display: none;
  }
  .cont-head-left-icon2 {
      display: unset;
  }
  .cont-head-right-icon {
      display: unset;
  }
  .cont-head-left img {
      display: unset;
  }
  .cont-head-right-btn-hide {
      display: none;
  }
  .cont-head-right-profile-hide {
      display: none;
  }
}



/* Style For Page */

.fullPage {
  width: 100%;
  min-height: 200px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.fullPage-head {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.fullPage-head h4 {
  color: #535f6b;
}

.fullPage-cont {
  padding: 10px;
  color: #535f6b;
}


/* style For Dashboard */

.dashboard {
  display: flex;
  flex-direction: column;
}

.dash-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


/* Style For Dash One */

.dash-one-item {
  width: 25%;
  padding: 15px;
  display: flex;
  box-sizing: border-box;
}

.dash-one-item-inner {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dash-one-item:nth-of-type(1) .dash-one-item-inner{
  background: #dff6f8;
}

.dash-one-item:nth-of-type(2) .dash-one-item-inner{
  background: #ffecbd;
}

.dash-one-item:nth-of-type(3) .dash-one-item-inner{
  background: #f9cacb;
}

.dash-one-item:nth-of-type(4) .dash-one-item-inner{
  background: #e1daff;
}

.dash-one-item-inner-right {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.dash-one-item:nth-of-type(1) .dash-one-item-inner-right{
  background: #05bbc9;
}

.dash-one-item:nth-of-type(2) .dash-one-item-inner-right{
  background: #ffca44;
}

.dash-one-item:nth-of-type(3) .dash-one-item-inner-right{
  background: #ff4b4b;
}

.dash-one-item:nth-of-type(4) .dash-one-item-inner-right{
  background: #876cfe;
}

.dash-one-item-inner-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dash-one-item-inner-left span:nth-of-type(1){
  font-size: 35px;
}


@media only screen and (max-width:940px) {
  .dash-one-item {
      width: 50%;
  }
}

@media only screen and (max-width:760px) {
  .dash-one-item-inner-right {
      display: none;
  }
  .dash-one-item-inner-left span:nth-of-type(1) {
      font-size: 25px;
  }
  .dash-one-item-inner {
      height: 100px;
  }
  .dash-one-item {
      padding: 5px;
  }
}


/* Style For Dash Two */

.dash-two-item {
  width: 25%;
  padding: 15px;
  display: flex;
  box-sizing: border-box;
}

.dash-two-item-inner {
  width: 100%;
  height: 90px;
  border-radius: 20px;
  background: #fff;
  border:1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.dash-two-item-inner-left {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-two-item:nth-of-type(1) .dash-two-item-inner-left{
  background:#dff6f8 ;
  color: #05bbc9;
}

.dash-two-item:nth-of-type(2) .dash-two-item-inner-left{
  background:#ffecbd ;
  color: #ffca44;
}

.dash-two-item:nth-of-type(3) .dash-two-item-inner-left{
  background:#f9cacb ;
  color: #ff4b4b;
}

.dash-two-item:nth-of-type(4) .dash-two-item-inner-left{
  background:#e1daff ;
  color: #876cfe;
}

.dash-two-item-inner-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dash-two-item-inner-right span:nth-of-type(1){
  font-size: 20px;
}

.dash-two-item-inner-right span:nth-of-type(2){
  color: #535f6b;
}


@media only screen and (max-width:940px) {
  .dash-two-item {
      width: 50%;
  }
}

@media only screen and (max-width:650px) {
  .dash-two-item-inner {
      flex-direction: column;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .dash-two-item {
      padding:5px;
  }
  .dash-two-item-inner-left {
      order: -1;
  }
}



/* Style For Dash Three */

.dash-three-item {
  width: 25%;
  padding: 15px;
  display: flex;
  box-sizing: border-box;
}

.dash-three-item-inner {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}

.dash-three-item-inner p {
  color: #535f6b;
}

.dash-three-item-inner-cont {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dash-three-item-inner-cont-icon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-three-item:nth-of-type(1) .dash-three-item-inner-cont-icon {
  background:#dff6f8 ;
  color: #05bbc9;
}

.dash-three-item:nth-of-type(2) .dash-three-item-inner-cont-icon {
  background:#ffecbd ;
  color: #ffca44;
}

.dash-three-item:nth-of-type(3) .dash-three-item-inner-cont-icon {
  background:#f9cacb ;
  color: #ff4b4b;
}

.dash-three-item:nth-of-type(4) .dash-three-item-inner-cont-icon {
  background:#e1daff ;
  color: #876cfe;
}

.dash-three-item-inner-cont-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dash-three-item-inner-cont-info span:nth-of-type(1){
  font-size: 25px;
}

.dash-three-item-inner-cont-info span:nth-of-type(2){
  color: #535f6b;
}

.dash-three-item-inner-range {
  width: 85%;
  background: #c7cbd3;
  box-shadow: 0 0 0;
  height: 6px;
  border-radius: 8px;
  margin-top: 35px;
  position: relative;
  overflow: hidden;
}

.dash-three-item-inner-range span {
  height: 100%;
  width: 50%;
  display: block;
}

.dash-three-item:nth-of-type(1) .dash-three-item-inner-range span {
  background:#05bbc9 ;
}

.dash-three-item:nth-of-type(2) .dash-three-item-inner-range span {
  background:#ffca44 ;
}

.dash-three-item:nth-of-type(3) .dash-three-item-inner-range span {
  background:#ff4b4b ;
}

.dash-three-item:nth-of-type(4) .dash-three-item-inner-range span {
  background:#876cfe ;
}


@media only screen and (max-width:940px) {
  .dash-three-item {
      width: 50%;
  }
}

@media only screen and (max-width:650px) {
  .dash-three-item {
      width: 100%;
  }
}
  
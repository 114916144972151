/* import Font */
/* cyrillic-ext */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofA-seUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofA-seUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofA-seUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofA-seUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofA-seUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAnsSUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAnsSUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAnsSUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAnsSUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAnsSUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* End Font */
body {
    background: #f6f5f5 ;
    font-family: 'Montserrat', sans-serif !important;
    scroll-behavior: smooth;
}

h1 , h2 , h3 , h4 , h5 , h6 , p , span , b , div {
    font-family: 'Montserrat', sans-serif !important;

}

.main {
    padding-bottom:3px;
    display: flex;
    flex-direction: column;
}

:root {
    /* --main-color:#95c41f; */
    --main-color:#3f70ca;
}

/* Style For Head */

.head {
    background: #fff;
}

.head-one {
    height:35px;
    background: #262626;
    color: #fff;
}


.head-one-init {
    height: 35px;
    width:80%;
    font-size: 12px;
    padding-top: 7px;
    display: flex;
    flex-direction: row;
    margin:0 auto;
}

.head-one-init  .anticon {
    margin:3px 5px 0 17px;
}

.head-menu {
    width: 80%;
    margin:0 auto;
}
.submit_property_menu_item {
    visibility: hidden;
}
.head-menu-button {
    height: 35px;    
    float: right;
    margin-top: 16px;
    background: #fff;
    border:none;
    border-radius: 20px;
    border:1px solid var(--main-color);
    color: var(--main-color);
    padding: 0px 15px 0 15px;
    transition: all 0.2s;
    cursor: pointer;
}
.head-menu-button:hover {
    background: var(--main-color);
    color: #fff;
    transition: all 0.2s;
}
.head-menu  img {
    height: 45px;
}
.head-logo-item {
    padding: 5px;
}
.head-menu li , .head-menu span {
    font-family:Nunito ;
    font-size: 17px;
}
.head-menu .ant-menu-item , .head-menu .ant-menu-submenu {
    padding:10px !important;
}
/* End Style For Head */


/* Style FOr Slider */
.slider {
    width: 100%;
}

.slider img {
    width:100%;
    height: 100%;
}
/* End For Slider */

/* Style For Filter */
.filter-first {
    width: 80%;
    margin:0 auto;
    margin-top: -350px;
    position: relative;
    z-index: 100;
    margin-bottom: 50px; 
}

.filter-list {
    width: 80%;
    margin:0 auto;
    z-index: 100;
    margin-bottom: 50px; 
}

.filter .ant-tabs-nav {
    margin-bottom: 0 !important;
}

.filter .ant-tabs-nav-wrap {
    justify-content: center;
}

.filter .ant-tabs-tab {
    height: 60px;
    margin-right: 5px !important;
}

.filter .ant-tabs-tab .ant-tabs-tab-btn {
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    color: #9b9999;
    background: #fff;
    padding: 3px 15px 4px 15px;
    border-radius: 18px;
    }

.filter .ant-tabs-tab-active .ant-tabs-tab-btn  {
    background: var(--main-color) !important;
    color:#fff !important;
}

.filter .ant-tabs-ink-bar {
    background: var(--main-color) !important;
}

.filter .ant-tabs-content-holder {
    background-color: rgba(256, 256, 256, 0.9);
    padding: 10px;
    border:6px solid #fff;
}



/* Style For Filter Form */
#filter-form {
    display: flex;
    flex-wrap: wrap;
}

#filter-form .ant-form-item {
    width: 25%;
    padding: 5px !important;
    margin-bottom: 0 !important;
}

#filter-form .ant-input-number {
    width: 100%;
    height: 45px;
}

#filter-form .ant-select-selector ,#filter-form  .ant-picker-range {
    height: 45px;
    width: 100%;
}

#filter-form .ant-form-item button {
    width: 100%;
}

#filter-form .ant-select-selection-item {
    font-size: 18px;
}

#filter-form .ant-btn {
    height:45px;
    background: var(--main-color);
    color: #fff !important;
}

#filter-form .ant-btn:hover {
    border:none !important;
}



.more-filter {
    width: 100%;
    margin:5px 0 0 10px;
}

.more-filter  span {
    cursor: pointer;
}

/* End Filter Style */



/* Style For product-section */
.product-section {
    width: 80%;
    margin:0 auto;
    margin-top: 150px;
    margin-bottom: 100px;
    padding:5px;
    display: flex;
    flex-direction: column;
}

.product-section-head {
    height: 40px;
    width: 100%;
    text-align: center;
    font-size: 24px;
}

.product-section-cont {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.product-section .ant-tabs {
    margin-top: 25px;
}

.product-section .ant-tabs-nav-wrap {
    justify-content: center;
}

.product-section .ant-tabs-tab .ant-tabs-tab-btn {
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    color: #9b9999;
    background: #fff;
    padding: 3px 15px 4px 15px;
    border-radius: 18px;
}

.product-section .ant-tabs-tab-active .ant-tabs-tab-btn  {
    background: var(--main-color) !important;
    color:#fff !important;
}

.product-section .ant-tabs-ink-bar {
    background: var(--main-color) !important;
}

.product-section .ant-tabs-tabpane {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.product-section .ant-tabs-nav .ant-tabs-nav-wrap {
    padding-left: 170px;
    padding-right: 170px;
}

.product-section .ant-tabs-nav .ant-tabs-tab {
    margin-right: 10px;
}

/* .product-section .ant-tabs-content-holder {
    min-height: 370px;
    position: relative;
} */

.product-section-main-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: red;
    z-index: 10;
}

.product-section-card {
    width: 25%;
    padding:5px;
}

.product-section-card-init {
    background: cornflowerblue;
    width: 100%;
    height: 360px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
    background: #fff;
    position: relative;
}

.product-section-card-init img {
    width: 100%;
    height: 250px;
}

.product-section-card-init-des {
    width:100%;
    height: 110px;
    display: flex;
    flex-direction: column;
}

.product-section-card-init-des-one {
    width: 100%;
    height:65px;
    padding: 7px;
}

.product-section-card-init-des-one h3 {
    color: var(--main-color);
    font-weight: 600;
}

.product-section-card-init-des-two {
    width: 100%;
    height:45px;
    display: flex;
    flex-direction: row;
}

.product-section-card-init-des-two-item {
    height: 45px;
    border-top: 1px solid #e0e0e0;
    flex-grow: 1;
    text-align: center;
    font-size: 15px;
    padding-top:7px ;
}



/* End Product Section */

/* Start Agent Section */
.agent-section {
    width: 100%;
    height: 350px;
    margin-top: 100px;
    margin-bottom: 50px;
    background-image: url('../component/img/agentbg.jpg');
    background-position: center center;
    background-size: cover;
    display: flex;
    background-attachment: fixed;
}

.agent-section-cover {
    width: 100%;
    height: 100%;
    background: #00000079;
    padding: 10px;
}

.agent-section-cover-init {
    width: 70%;
    margin:0 auto; 
    display: flex;
    flex-direction: row;
}

.agent-section-cover-init-one {
    width: 70%;
    height: 320px;
}

.agent-section-cover-init-one-init {
    width: 80%;
    height: 240px;
    float: right;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
}

.agent-section-cover-init-one-init-item {
    width:50%;
    height: 120px;
    padding:5px;
}

.agent-section-cover-init-one-init-item-init {
    width: 100%;
    height:100%;
    background: #00000091;
    border-radius: 5px;
    border:1px solid #828282;
    display: flex;
    flex-direction: row;
}

.agent-section-cover-init-one-init-item-init .anticon  {
    font-size: 40px;
    color: var(--main-color);
    margin-top: 33px;
    margin-left: 10px;
}

.agent-section-cover-init-one-init-item-init h5{
    font-size: 20px;
    color: #fff;
    margin-top: 32px;
    margin-left: 10px;
}


.agent-section-cover-init-two {
    width: 30%;
    height: 320px;
}

.agent-section-cover-init-two-init {
    height: 150px;
    margin-top: 70px;
}

.agent-section-cover-init-two-init h3 {
    font-size: 25px;
    font-weight: 600;
    color:#fff;
}

.agent-section-cover-init-two-init p {
    font-size: 15px;
    color:#fff;
}




/* End Agent Section */

/* Start Popular Section */

.popular-section {
    width: 70%;
    margin:0 auto;
    margin-bottom: 50px;
    display: flex ;
    flex-direction: column;
    margin-top:50px ;
}

.popular-section-head {
    width: 100%;
    text-align: center;
}

.popular-section-head h3{
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 24px;
}

.popular-section-cont {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.popular-section-item {
    padding: 5px;
    overflow: hidden;

}

.popular-section-item-init {
    width: 100%;
    height: 430px;
    background-color: #f6f5f5;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
    overflow: hidden;
    position: relative;
}

.popular-section-item-init-img {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.popular-section-item-init-img img {
    width: 100%;
    height: 100%;
    transition: all 0.6s;
}

.popular-section-item-init-img img:hover {
    transform: scale(1.1) rotate(2deg);
    transition: all 0.6s;
}

.popular-section-item-init-img-radius {
    width: 130px;
    height: 130px;
    position: absolute;
    border-radius: 50%;
    background: #ffffffd5;
    top: -4%;
    right: -5%;
    transform: scale(0);
    transition: all 0.3s;
    padding: 10px;
}

.popular-section-item-init:hover .popular-section-item-init-img-radius {
    transform: scale(1);
}

.popular-section-item-init-img-radius-init {
    width: 100%;
    height: 100%;
    background: var(--main-color);
    border-radius: 50%;
    transition: 0.3;
    transition-delay: 0.2;
    transform: scale(0);
    text-align: center;
    color: #fff;
    font-size: 25px;
    padding-top: 29%;
}

.popular-section-item-init:hover .popular-section-item-init-img-radius-init {
    transform: scale(1);
}

.popular-section-item-init-des {
    height:180px;
    display: flex;
    flex-direction: column;
}

.popular-section-item-init-des-one {
    width:100%;
    height: 130px;
    text-align: center;
    padding: 5px;
}

.popular-section-item-init-des-one-warp {
    width: 120px;
    height: 50px;
    background: #ffffffd5;
    position: absolute;
    transition: all 0.5s;
    transform: skewX(-20deg);
    top: 46%;
    right: 120%;
    transition-delay: 0.7;
    padding: 0 10px 10px 0;
}

.popular-section-item-init:hover .popular-section-item-init-des-one-warp {
    right: 67%;
}

.popular-section-item-init-des-one-warp-init {
    width:100% ;
    height: 100%;
    background: var(--main-color);
    transform: skewX(1deg);
    color: #fff;
    font-size: 17px;
    padding-top: 4px;
}

.popular-section-item-init-des-one  h3 {
    font-size: 21px;
}

.popular-section-item-init-des-two {
    width:100%;
    height: 50px;
    text-align: center;
    font-size: 15px;
    padding:11px;
    color: var(--main-color);
}
/* End popular-section */


/* Start Brand Section */
.brand-section {
    width:100%;
    margin-top: 50px;
    margin:0 auto;
    padding:0 5px 0 5px; 
}

.brand-section-init {
    width: 70%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
}

.brand-section-init-head {
    width: 100%;
    height: 40px;
    text-align: center;
    margin-bottom: 20px;
}

.brand-section-init-head h3 {
    font-size: 23px;
    color: #000;
}

.brand-section-init-cont {
    width: 100%;
}

/* .brand-section-init-cont .owl-item {
    height: 80px;
} */

.brand-section-init-cont .owl-item .item {
    text-align: center;
    padding: 10px;
}

.brand-section-init-cont .owl-item img {
    width: 70% !important;
    height: 100%;
    margin:0 auto;
    filter: grayscale(100%);
    cursor: pointer;
}

.brand-section-init-cont .owl-item img:hover {
    filter: unset;
}

.brand-section-init-cont .owl-dots {
    display: none;
}

.brand-section-init-cont .owl-prev {
    position: absolute;
    left: 0;
    margin-top: -90px !important;
    font-size: 45px !important;    
 }

 .brand-section-init-cont .owl-next {
    position: absolute;
    right: 0;
    margin-top: -90px !important;
    font-size: 45px !important;    
 }



 /* Style For Footer */
.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer-one {
    width: 100%;
    background: #19192c;
}

.footer-one-init {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    margin:0 auto;
    margin-top: 70px;
    
}

.footer-one-item {
    max-height: 320px;
    padding: 10px;
    color:#969696;
    text-align: justify;
    line-height: 27px;
}

.footer-one-item .ant-row {
    margin-bottom: 10px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
}

.footer-one-item .ant-row .anticon {
    margin-top: 7px;
    margin-right: 10px;
}

.footer-one-item-des {
    font-size: 12px;
}

.footer-one-item h3 {
    color:#c5c5c5;
}

.footer-one-item-post {
    width: 100%;
    height: 70px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
}

.footer-one-item-post-img {
    width: 70px;
    height: 70px;
}

.footer-one-item-post-img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.footer-one-item-post-des {
    width:calc(100% - 70px); 
    height: 70px;
    padding: 0px 7px 7px 7px;
    overflow: hidden;
    font-size: 12px;
}

.footer-one-item-post-des h4 {
    font-size: 16px !important;
    color:#969696;
}

.footer-two {
    width: 100%;
    background: #1b1b2f;
}

.footer-two-init {
    width: 70%;
    height: 70px;
    margin:0 auto;
    text-align: center;
    color: #969696;
    padding-top: 20px;
}


/* Style FOr Head LISt */
.headlist {
    width: 100%;
    background-image: url('../component/img/agentbg.jpg');
    background-size:cover;
    background-position: center center;
    padding-bottom: 2px;
    background-attachment: fixed;
}


/* Style For Product Lisi Page */

.listproductpage {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.listproductpage-init {
    width: 80%;
    margin:0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.listproductpage-init-card {
    width: 25%;
    height: 380px;
    margin-bottom: 5px;
    padding:5px;
}

.listproductpage-init-card-init {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 20px 0 rgba(62,28,131,0.1);
    display: flex;
    flex-direction: column;
    position: relative;
}

.listproductpage-init-card-init img {
    width: 100%;
    height: 250px;
}

.listproductpage-init-card-init-des {
    height:80px;
    padding: 7px;
}

.listproductpage-init-card-init-des h3 {
    font-weight: 600;
    color: var(--main-color);
}

.listproductpage-init-card-init-des-price {
    color:#6D6D6D;
    font-weight: 600;
}

.listproductpage-init-card-init-more {
    height: 50px;
    border-top: 1px solid #dcdcdc;
    display: flex;
    flex-direction: row;
}

.listproductpage-init-card-init-more-one {
    width: 50%;
    height: 45px;
    padding: 11px;
    font-weight: 500;
}

.listproductpage-init-card-init-more-two {
    width: 50%;
    height: 45px;
}

.listproductpage-init-card-init-more-two button {
    border:none;
    background: var(--main-color);
    float: right;
    margin: 4px 10px;
    height: 30px;
    padding: 0 26px;
    color: #fff;
    cursor: pointer;
}


/* Style For Detail */

.head-detial {
    width: 100%;
    height: 300px;
    background-image: url('../component/img/detalihead.jpg');
    background-size: cover ;
    background-position: center center;
}

.head-detial-cover {
    width: 100%;
    height: 100%;
    background: #00000079;
    padding-top: 100px;
    text-align: center;
    color: #fff;
}

.head-detial-cover h2 , .head-detial-cover h4 {
    color: #fff;
}

.detail-cont {
    width: 80%;
    margin:0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    padding: 2px;
}

.detail-cont-one {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding:5px;
    background: #fff;
}

.detail-cont-one-head {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
}

.detail-cont-one-head-left {
    width: 65%;
    padding: 0 8px;
}

.detail-cont-one-head-left p {
    margin-top: -15px;
    color:#6D6D6D;
}

.detail-cont-one-head-left h1 {
    font-weight: 600;
    font-size: 25px;
}

.detail-cont-one-head-right {
    width: 35%;
    height: 60px;
    padding: 10px;
}

.detail-cont-one-head-right h3 {
    color:var(--main-color);
    float: right;
    font-size: 25px;
    font-weight: 600;
}

.carousel-detail {
    width: 100%;
    margin-top: 10px;
}

.carousel-detail-item {
    height: 450px;
    width: 100%;
    background: #e0e0e0;
}

.carousel-detail-item img {
    width: 100%;
    height: 100%;
}


.detail-tab {
    width: 100%;
    margin-top: 30px;
    border-bottom: 1px solid #e0e0e0;
}

.detail-tab .ant-tabs-tab {
    width: 100px;
    box-shadow:1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 7px !important;
    margin-right: 12px !important;
}

.detail-tab .ant-tabs-nav::before {
    border-bottom: none !important;
}

.detail-tab .ant-tabs-tab-active {
    background: var(--main-color) !important;
}

.detail-tab .ant-tabs-tab:hover {
    background: var(--main-color);
}

.detail-tab .ant-tabs-tab-btn:hover {
    color: #fff;
}

.detail-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
    font-size: 17px;
    font-weight: 600;
}

.detail-tab .ant-tabs-tab-btn {
    margin:0 auto;
    font-size: 17px;
    font-weight: 600;
}

.detail-tab .ant-tabs-ink-bar {
    display: none;
}

.detail-tab .ant-tabs-nav-list {
    padding: 3px;
}

.detail-tab .ant-tabs-tabpane {
    text-align: justify;
    padding: 7px;
    line-height: 30px;
}

.detail-tab .ant-tabs-tabpane h3 {
    font-size:17px;
    font-weight: 600;
}

.detail-tab .detail-tab-item-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.detail-tab .detail-tab-item-panel-item {
    width: 25%;
    height: 40px;
    padding:4px;
}
.detail-tab .anticon {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: var(--main-color);
    color: #fff;
    padding-top: 3px;
}

.detail-map {
    height: 250px;
    border-top: 70px;
    width: 100%;
    margin-bottom: 50px;
}

.detail-map-head {
    width: 100%;
    height: 40px;
    padding:5px;
}

.detail-map-head h3 {
    font-weight: 600;
    font-size: 17px;
}

.detail-map .leaflet-container {
    width: 100%;
    height: 100%;
  }

.detail-cont-two {
    width: 30%;
    padding:0 5px 0 15px;
}

.detail-contact-agent {
    width: 100%;
    background: #fff;
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:20px;
}

.detail-contact-agent h3 {
    margin-top: 10px;
}

.detail-contact-agent img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.detail-contact-agent-social {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    color: #828282;
}

.detail-contact-agent-social .anticon {
    font-size: 20px;
}

.detail-contact-agent-social span {
    margin-left: 15px;
    font-size: 14px;
}

.detail-contact-agent-call {
    width: 100%;
}

.detail-contact-agent-call button {
    width: 100%;
    background: var(--main-color);
    border:none;
    color: #fff;
    height: 40px;
    cursor: pointer;
    margin-top: 10px;
}

.detail-contact-agent-wts {
    width: 100%;
}

.detail-contact-agent-wts button {
    width: 100%;
    background: #fff;
    border:1px solid #48C740;
    color: #48C740;
    height: 40px;
    cursor: pointer;
    margin-top: 10px;
    transition: all 0.3s;
}

.detail-contact-agent-wts button:hover {
    background: #48C740;
    color: #fff;
    transition: all 0.3s;
}

.modalSuite {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.modalSuiteItem {
    width: 100%;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
    padding:10px;
    display: flex;
}

.modalSuiteItem img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.modalSuiteItemCont {
    width: calc(100% - 250px);
    padding:0 10px 0 10px;
    display: flex;
    flex-wrap: wrap;
}

.modalSuiteItemContItem {
    height: 30px;
    display: flex;
    width: 20%;
    display: flex;
}

.modalSuiteItemContItem .anticon{
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: var(--main-color);
    color: #fff;
    padding-top: 3px;
    margin-top: 4px;
}

.modalSuiteItemContItem span:nth-of-type(2){
    margin-top: 2px;
    margin-left: 4px;
}

.modalSuiteItemBtn {
    width: 150px;
    height: 100px;
}

.modalSuiteItemBtn button {
    width: 100%;
    border: none;
    border-radius: 5px;
    background: var(--main-color);
    color: #ffff;
    height: 40px;
    margin-top: 30px;
    cursor: pointer;
}


@media only screen and (max-width:990px) {
    .modalSuiteItem {
        flex-direction: column;
    }
    .modalSuiteItemCont , .modalSuiteItemBtn {
        width: 100%;
    }
    .modalSuiteItem img{
        width: 100%;
        height: 150px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:600px) {
    .modalSuiteItemContItem {
        width: 33.33%;
    }
    .detail-tab .detail-tab-item-panel-item {
        width: 50%;
    }
}

/* Style For Comment */
.comment {
    width: 100%;
    background: #fff;
    margin-top: 10px;
}

.comment-head {
    width: 100%;
    height: 45px;
    padding: 5px;
    border-bottom: 1px solid #e0e0e0;
}

.comment-head h3 {
    font-weight: 600;
    font-size: 17px;
}

.comment-cont {
    width: 100%;
    padding: 5px;
}

.comment-cont-form {
    width: 100%;
    background: #f0f4f7;
    padding:10px;
    margin-top: 20px;
}

.comment-cont-form form {
    display: flex;
    flex-direction: column;
}

.comment-cont-form form textarea {
    width: 90%;
    height: 100px;
    margin: 20px 0 20px 0;
    border:none;
    padding: 10px;
}

.comment-cont-form form button {
    color: #fff;
    background: var(--main-color);
    height: 50px;
    width: 150px;
    border:none;
    cursor: pointer;
}

.comment-cont-item {
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
}

.comment-cont-item-icon {
    width: 180px;
    font-size: 28px ;
    border-radius: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 7px;
}

.comment-cont-item-icon .anticon {
    width: 50px;
    height: 50px;
    border: 1px solid #000;
    padding-top: 10px;
    border-radius: 50%;
}

.comment-cont-item-icon-username {
    font-size: 12px;
}

.comment-cont-item-Date {
    width: 120px;
    height: 70px;
    display: flex;
    justify-content: center;
}

.comment-cont-item-Date div {
    width: 100px;
    height: 40px;
    border:1px solid #e0e0e0;
    text-align: center;
    border-radius: 10px;
    color: #9b9999;
    margin-top: 12px;
    padding-top: 7px;
}

.comment-cont-item-Des {
    width: calc(100% - 300px);
    padding: 7px;
    text-align: justify;
    
}

@media only screen and (max-width:600px) {
    .comment-cont-form form textarea {
        width: 100%;
    }
    .comment-cont-item {
        flex-direction: column;
    }
    .comment-cont-item-icon {
        width: 100%;
    }
    .comment-cont-item-Date {
        width: 100%;
    }
    .comment-cont-item-Des {
        width: 100%;
    }
}


/* Style For Modal auth */

.tab-modal-auth .ant-tabs-tab{
    width: 50%;
}

.tab-modal-auth .ant-tabs-nav-list {
    width: 100%;
}

/* Style For change-color-setting */
.change-color-main {
    width: 70px;
    position: fixed;
    margin-top: 240px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.change-color-main-setting {
    width: 50px;
    height: 40px;
    background: #fff;
    border-radius: 0 10px 10px 0;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding-top: 5px;
    cursor: pointer;
}


#change-color-main-setting-icon{
    animation: mymove 5s infinite;
    animation-timing-function: linear	;
    font-size: 30px;
}

@keyframes mymove {
    to {transform: rotate(360deg)}
  }

#change-color-main-cont {
    width: 50px;
    height: 170px;
    background: #fff;
    border-radius: 0 10px 10px 0;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    margin-left: -100px;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    padding: 5px;
}  

.change-color-main-cont-item {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.3s;
    margin-left: -50px;
}

.change-color-main-cont-item:nth-of-type(1){
    background: #3f70ca;
    transition-delay: 0.3s;
}

.change-color-main-cont-item:nth-of-type(2){
    background: #95c41f;
    transition-delay: 0.4s;
}


.change-color-main-cont-item:nth-of-type(3){
    background: #da50bc;
    transition-delay: 0.5s;
}


.change-color-main-cont-item:nth-of-type(4){
    background: #f3db56;
    transition-delay: 0.6s;
}










@media only screen and (max-width:1140px) {
    .filter-first {
        width: 100%;
        margin-top: 0px;
    }
    .filter .ant-tabs-content-holder {
        background: #e0e0e0;
    }
    .product-section {
        width:100%;
        margin-top: 50px;
    }
    .popular-section  {
        width:100%;
    }
    .brand-section-init {
        width: 100%;
    }
    .footer-one-init {
        width: 100%;
    }
    .footer-two-init {
        width: 100%;
    }
    .agent-section-cover-init {
        width: 100%;
    }
    .listproductpage-init {
        width: 100%;
    }
    .detail-cont {
        width: 100%;
    }
}


@media only screen and (max-width:991px) {
    .head-menu-button {
        display: none;
    }
    .submit_property_menu_item {
        visibility: visible;
    }
    .product-section-card {
        width: 33%;
    }
    .agent-section-cover-init-two {
        display:none;
    }
    .agent-section-cover-init-one {
        width: 100%;
    }
    .agent-section-cover-init-one-init {
        width: 100%;
    }
    .listproductpage-init-card {
        width: 33.33%;
    }
}

@media only screen and (max-width:850px) {
    .detail-cont {
        flex-direction: column;
        padding: 10px;
    }
    .detail-cont-one {
        width: 100%;
        padding: 0px;
    }
    .detail-cont-two {
        width: 100%;
        padding:0px;
    }
    .detail-contact-agent {
        margin-top: 10px;
    }
}


@media only screen and (max-width:750px) {
    #filter-form .ant-form-item {
        width: 50%;
    }
    .form-filter-submit {
        order:2;
    }
    .product-section-card {
        width: 50%;
    }
    .agent-carousel {
        width: 100%;
    }
    .listproductpage-init-card {
        width: 50%;
    }
    .carousel-detail-item {
        height: 400px;
    }
    .head-detial {
        height: 250px;
    }
}

@media only screen and (max-width:570px) {
    .agent-carousel-item-img {
        width:40%;
    }
    .agent-carousel-item-des {
        width:60%;
    }
    .carousel-detail-item {
        height: 320px;
    }
}


@media only screen and (max-width:500px) {
    .head-one {
        display: none;
    }
    .head-menu {
        width: 100%;
    }
    #filter-form .ant-form-item {
        width: 100%;
    }
    .product-section {
        margin-bottom: 0;
    }
    .product-section-card-init {
        height: auto;
    }
    .product-section-card-init img {
        height: 150px;
    }
    .product-section-card-init-des-one h3 {
        font-size: 13px;
    }
    .product-section-card-init-des-two-item .anticon {
        display: none;
    }
    .product-section-card-init-des-two-item-icon-hotel {
        display: unset !important;
        margin-top: 5px;
    }
    .product-section-card-init-des-one {
        min-height: 40px;
        overflow: hidden;
        height: auto;
    }
    .product-section-card-init-des-two-item {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        padding-top: 2px;
        overflow: hidden;
    }
    .product-section-card-init-des {
        height: auto;
    }
    .agent-section-cover-init-one-init-item-init .anticon {
        font-size:20px ;
    }
    .agent-section-cover-init-one-init-item-init h5 {
        font-size:15px ;
    }
    .listproductpage-init-card {
        width: 100%;
    }
    .detail-cont-one-head {
        flex-direction: column;
    }
    .detail-cont-one-head-right , .detail-cont-one-head-left {
        width: 100%;
        height: auto;
    }
    .carousel-detail-item {
        height: 250px;
    }
    .detail-cont-one-head-right h3 , .detail-cont-one-head-left h1 {
        font-size: 17px;
    }
    .head-detial {
        height: 220px;
    }
}


@media only screen and (max-width:400px) {
    .carousel-detail-item {
        height: 200px;
    }
    .head-detial {
        height: 180px;
    }
}

@media only screen and (max-width:250px){
    .product-section-card {
        width: 100%;
    }
}



/* Style For Loader */


.loader {
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    position: fixed;
    background: rgba(255, 255, 255, 0.603);
    z-index: 999999;
}

.loader2 {
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    position: fixed;
    background: rgba(255, 255, 255);
    z-index: 999999;
}


.ball-pulse-sync{
    width: 65px;
    height: 50px;
    margin: 0 auto;
    margin-top: 300px;
}


@-webkit-keyframes ball-pulse-sync {
33% {
 -webkit-transform: translateY(10px);
         transform: translateY(10px); }
66% {
 -webkit-transform: translateY(-10px);
         transform: translateY(-10px); }
100% {
 -webkit-transform: translateY(0);
         transform: translateY(0); } }

@keyframes ball-pulse-sync {
33% {
 -webkit-transform: translateY(10px);
         transform: translateY(10px); }
66% {
 -webkit-transform: translateY(-10px);
         transform: translateY(-10px); }
100% {
 -webkit-transform: translateY(0);
         transform: translateY(0); } }

.ball-pulse-sync > div:nth-child(1) {
-webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
       animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out; }

.ball-pulse-sync > div:nth-child(2) {
-webkit-animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
       animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out; }

.ball-pulse-sync > div:nth-child(3) {
-webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
       animation: ball-pulse-sync 0.6s 0s infinite ease-in-out; }

.ball-pulse-sync > div {
background-color:var(--main-color);
width: 15px;
height: 15px;
border-radius: 100%;
margin: 2px;
-webkit-animation-fill-mode: both;
       animation-fill-mode: both;
display: inline-block; }


/* Style For Image Loader  */

.loaderImage {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loaderImageInner {
    height: 15px;
    width: 105px;
    display: flex;
    position: relative;
}
.loaderImageInner .loaderImageInnerCircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--main-color);
    animation: move 500ms linear 0ms infinite;
    margin-right: 30px;
}
.loaderImageInner .loaderImageInnerCircle:first-child {
    position: absolute;
    top: 0;
    left: 0;
    animation: grow 500ms linear 0ms infinite;
}
.loaderImageInner .loaderImageInnerCircle:last-child {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    animation: grow 500ms linear 0s infinite reverse;
}
@keyframes grow {
    from {
        transform: scale(0, 0);
        opacity: 0;
   }
    to {
        transform: scale(1, 1);
        opacity: 1;
   }
}
@keyframes move {
    from {
        transform: translateX(0px);
   }
    to {
        transform: translateX(45px);
   }
}


/* Style For privacy */

.privacy {
    width:90%;
    margin:0 auto;
    margin-top: 30px;
    padding-top: 30px;
    padding: 30px 10px 10px 10px;
    text-align: justify;
    border:3px solid #e0e0e0;
    line-height: 32px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.privacy h4 {
    font-size: 14px;
    font-weight: 700;
}

.privacy h3 {
    font-size: 16px;
    font-weight: 700;
}


.privacy p , .privacy span {
    text-align: justify;
}

.privacy span {
    margin-top: -12px;
}

.privacy p {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
}



@media screen and (max-width: 900px) {
    .privacy {
        margin-top: 10px;
      }
      
  }
